import { Box, SxProps, type Theme } from '@mui/material';
import { Document } from 'services/documents/entities/Document';
import { DocumentsList } from '../components/documents-list/DocumentsList';
import { TrashListItem } from './TrashListItem';

export function TrashList({ documents, sx }: { documents: Document[]; sx: SxProps<Theme> }) {
  return (
    <DocumentsList dataTestId="trash-list" sx={sx}>
      {documents.map((document) => (
        <Box key={document.id}>
          <TrashListItem document={document} />
        </Box>
      ))}
    </DocumentsList>
  );
}
