import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SecondaryMenu, SecondaryMenuItemTitle, SecondaryMenuSectionTitle } from 'components/editor/SidePanel/component';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllPanels, setActiveTableSettingsPanel } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
import { useTableManipulation } from 'components/editor/GridDndEditor/Block/Table/useTableManipulation';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { RootState } from '../../../grid/reduxStore/Store';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { selectContentTable, selectBlockContent } from '../../../grid/reduxStore/editorSlice';
import { TableTypeIdentifier } from '../../../grid/reduxStore/table.types';
import { useBlockDeletedHandler } from '../../../hooks/UseBlockDeletedHandler';

export const DEFAULT_DECIMAL_PLACES = 2;

const DECIMAL_PLACES = ['0', '1', '2', '3', '4', '5'];
const label = { inputProps: { 'aria-label': 'show totals' } };

const TableDefaultSettings = () => {
  const { toggleTableType } = useTableManipulation();
  const { t } = useTranslation();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const dispatch = useDispatch();
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const isToggleOn = useSelector((state: RootState) => state.blockStyleSettings.activeTotalToggle) || false;
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
  const blockContent = useSelector((state: RootState) => selectBlockContent(state, sectionId, blockId)) || undefined;
  const [showTotals, setShowTotals] = useState<boolean>(isToggleOn);
  const [isToggleAvailable, setIsToggleAvailable] = useState<boolean>(true);
  const { setTableDecimalPlaces } = useTableManipulation();
  const [selectedDecimalPlaces, setSelectedDecimalPlaces] = useState<string>(
    String(tableData?.metadata?.pricingDecimalPlaces ?? DEFAULT_DECIMAL_PLACES)
  );
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const isPricingTable = useMemo(() => tableData?.metadata?.tableType === TableTypeIdentifier.PRICING_TABLE, [tableData]);
  const pricingDecimalPlaces = useMemo(() => tableData?.metadata?.pricingDecimalPlaces, [tableData]);
  const isAllIdsAvailable = selectedBlockIdByWrapper && sectionId;

  useEffect(() => {
    if (tableData) {
      setShowTotals(isPricingTable);
      setSelectedDecimalPlaces(String(pricingDecimalPlaces));
    }
  }, [tableData, isPricingTable, pricingDecimalPlaces]);

  const handleDecimalPlacesChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;

    if (!isAllIdsAvailable || isNaN(Number(selectedValue))) return;
    setTableDecimalPlaces(selectedBlockIdByWrapper, sectionId, Number(selectedValue));
    setSelectedDecimalPlaces(selectedValue);
  };

  const handleShowTotalsChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setIsToggleAvailable(false);
      setShowTotals(event.target.checked);

      try {
        const updatedTableData = toggleTableType(blockId, sectionId);
        if (!updatedTableData || !blockContent) return;
        if (!updatedTableData) return;

        if (!updatedTableData.rows.length) {
          await gridBlockDeletedHandler(sectionId, blockId, blockContent).then(() => {
            dispatch(resetAllPanels());
          });
          return;
        }

        const result = await blockContentChangedHandler(blockId, sectionId, updatedTableData);

        if (result.status === 'Ok') {
          setIsToggleAvailable(true);
        }
      } catch (error) {
        setIsToggleAvailable(true);
        throw new Error('Error while updating table data');
      }
    },
    [blockId, sectionId, toggleTableType, blockContentChangedHandler]
  );

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  return (
    <SecondaryMenu
      testId={'table-default-settings'}
      menuTitle={t('document.grid.table.setting.table')}
      backButtonOnClick={handleBackButtonClick}
    >
      <Stack rowGap={2} width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.table.setting.pricing')}</SecondaryMenuSectionTitle>
        {/* Decimal Places */}
        <Grid container direction="column" rowGap={1}>
          <SecondaryMenuItemTitle>{t('document.grid.table.setting.decimal_places')}</SecondaryMenuItemTitle>
          <FormControl size="small" fullWidth>
            <Select data-testid="decimal-places-select" value={selectedDecimalPlaces} onChange={handleDecimalPlacesChange}>
              {DECIMAL_PLACES.map((place) => (
                <MenuItem key={place} value={place}>
                  {place}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Stack>
      {/* Show Totals */}
      <Grid container alignItems="center" justifyContent="space-between">
        <SecondaryMenuItemTitle>{t('document.grid.table.setting.show_totals')}</SecondaryMenuItemTitle>
        <Grid item>
          <Switch
            data-testid="pricing-toggle"
            disabled={!isToggleAvailable}
            {...label}
            checked={showTotals}
            onChange={handleShowTotalsChange}
          />
        </Grid>
      </Grid>
    </SecondaryMenu>
  );
};

export default TableDefaultSettings;
