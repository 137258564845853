import { Box, Drawer } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ActivitiesProvider } from './activities/ActivitiesProvider';
import { ExpandedHeader } from './headers/ExpandedHeader';
import { ShrunkHeader } from './headers/ShrunkHeader';
import { InsightsExpandedContent } from './InsightsExpandedContent';
import { InsightsShrunkContent } from './InsightsShrunkContent';

const LEFT_SIDEBAR_WIDTH = 56;
const LEFT_SIDEBAR_BREAKPOINT = 1000;

export interface ExpandablePanelProps {
  isOpen: boolean;
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
}

export function ExpandablePanel({ isOpen, onClose }: ExpandablePanelProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const onCloseDrawer = () => {
    setIsExpanded(false);
    onClose({ isExpanded });
  };
  const onToggleExpand = () => setIsExpanded(!isExpanded);

  const drawerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // add smooth resize animation to the drawer
    if (drawerRef.current && !drawerRef.current.style.transition.includes('width 0.5s')) {
      drawerRef.current.style.transition = drawerRef.current.style.transition + ', width 0.5s';
    }
  }, [drawerRef.current]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCloseDrawer}
      data-testid="insights-drawer"
      PaperProps={{
        'data-testid': 'insights-panel-content',
        ref: drawerRef,
        sx: {
          overflowX: 'hidden',
          width: isExpanded ? { xs: '100vw' } : { xs: '60vw', xl: '50vw' },
          ...(isExpanded && {
            [`@media (min-width:${LEFT_SIDEBAR_BREAKPOINT}px)`]: {
              width: `calc(100vw - ${LEFT_SIDEBAR_WIDTH}px)`,
            },
            backgroundColor: 'background.default',
          }),
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          data-testid="insights-header"
          display="flex"
          alignItems="center"
          padding="1rem 1.5rem"
          bgcolor="background.paper"
          height="4.5rem"
        >
          {isExpanded ? (
            <ExpandedHeader onClose={onCloseDrawer} onToggleExpand={onToggleExpand} />
          ) : (
            <ShrunkHeader onClose={onCloseDrawer} onToggleExpand={onToggleExpand} />
          )}
        </Box>
        <ActivitiesProvider>{isExpanded ? <InsightsExpandedContent /> : <InsightsShrunkContent />}</ActivitiesProvider>
      </Box>
    </Drawer>
  );
}
