import { ListItem } from '@mui/material';
import { ReactNode, useRef } from 'react';
import { Document } from 'services/documents/entities/Document';
import { statusColors } from '../../utils/statusColors';

type DocumentListItemProps = {
  status: Document['status'];
  setIsHovered: (isHovered: boolean) => void;
  children: ReactNode;
  onClick: () => void;
  dataTestId: string;
};

export const DocumentListItem = ({ status, children, setIsHovered, onClick, dataTestId }: DocumentListItemProps) => {
  const listItemRef = useRef(null);

  const handleOnHover = (e: React.MouseEvent) => {
    if (e?.target === listItemRef.current) {
      setIsHovered(true);
    }
  };

  return (
    <ListItem
      data-testid={dataTestId}
      ref={listItemRef}
      divider
      tabIndex={0}
      sx={{
        gap: 2,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        '&:focus': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        },
        borderLeft: `4px solid ${statusColors[status]}`,
      }}
      onMouseEnter={handleOnHover}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      {children}
    </ListItem>
  );
};
