import { useQuery } from 'react-query';
import { useInsightsContext } from '../InsightsProvider';
import { ViewedSections } from '../types';

export function useQueryViewedSections() {
  const { documentId } = useInsightsContext();
  const { data, isLoading } = useQuery<ViewedSections[]>(['getViewedSections', documentId], () => mockViewedSections);

  const result = !data ? [] : data;

  return {
    result,
    isLoading,
  };
}

const mockViewedSections: ViewedSections[] = [
  { id: 1, name: 'Pricing', viewedTimeInSeconds: 600 },
  { id: 2, name: 'Agreement', viewedTimeInSeconds: 480 },
  { id: 3, name: 'Service and Offerings', viewedTimeInSeconds: 360 },
  { id: 4, name: 'About Us', viewedTimeInSeconds: 180 },
  { id: 5, name: 'Timeline', viewedTimeInSeconds: 90 },
];
