import { useContext } from 'react';
import { useTableManipulation } from './useTableManipulation';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content/SidePanelModelsProvider';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';
import { SelectionContext } from '../../SelectedBlockInfoProvider';
import CellDropDown from './components/CellDropDown';

type TooltipInputProps = {
  params: any;
  blockId: string;
  sectionId: string;
};

const CellDropdown = ({ params, blockId, sectionId }: TooltipInputProps): React.ReactElement => {
  const { addCustomFieldToRow } = useTableManipulation();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext);

  const { id, field, colDef, row } = params;
  const formatterKey = colDef?.columnType?.toLowerCase();
  const valueFormatter = row?.cellConfig?.[formatterKey]?.valueFormatter || '';
  const isCellHidden = row?.cellConfig?.[field]?.hideCell;
  const selectedValue = isCellHidden ? 'Hide cell' : valueFormatter;

  const optionsMenu = row?.optionsMenu;

  const changeCellFormatValue = async (value: string) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!selectedSectionId) throw new Error('Section id cannot be empty');
    try {
      let cellConfigObject = {};
      if (value === 'Hide cell') {
        cellConfigObject = { hideCell: true };
      } else {
        cellConfigObject = { valueFormatter: value, hideCell: false };
      }
      const updatedTableData = addCustomFieldToRow({ blockId, sectionId, field: formatterKey, id, cellConfigObject });
      if (!updatedTableData) return;
      const result = await blockContentChangedHandler(blockId, sectionId, updatedTableData);
      if (result.status === 'Ok') {
        return;
      }
    } catch (error) {
      throw new Error('Error while updating table data');
    }
  };

  return (
    <>
      <span className="py-value-formatter">{`${valueFormatter}`}</span>
      <CellDropDown selectedValue={selectedValue} changeCellFormatValue={changeCellFormatValue} options={optionsMenu} />
    </>
  );
};

export default CellDropdown;
