import { IconButton, Tooltip } from '@mui/material';
import { openNotification } from 'components';
import IcoRestoreDocument from 'components/icon/icons/IcoRestoreDocument';
import { openToast } from 'components/toast';
import { useTranslation } from 'react-i18next';
import { type Document } from 'services/documents/entities/Document';
import { useTrashRestore } from '../documents-tab/document-actions/useTrashRestore';

interface RestoreDocumentActionProps {
  documentToRestore: Document;
}

export function RestoreDocumentAction({ documentToRestore }: RestoreDocumentActionProps) {
  const { t } = useTranslation();
  const { handleProceed, handleUndo } = useTrashRestore({
    listType: 'Trash',
    document: documentToRestore,
  });

  const onSuccess = async () => {
    openToast({
      message: t('pipeline.tabs.trash_document_list.action_message'),
      onUndo: async () => handleUndo({}),
    });
  };

  const onFailure = () => {
    openNotification({
      type: 'error',
      title: t('pipeline.error.restore_error_msg'),
      description: t('pipeline.error.error_description'),
      placement: 'top',
    });
  };

  return (
    <Tooltip title={t('pipeline.tabs.trash_document_list.action_tooltip')}>
      <IconButton
        aria-label="Restore"
        onClick={() => handleProceed({ onSuccess, onFailure })}
        data-testid={`trash-area-${documentToRestore.title}`}
      >
        <IcoRestoreDocument />
      </IconButton>
    </Tooltip>
  );
}
