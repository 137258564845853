import { GridProps, styled } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { COLORS } from 'muiTheme/MuiDataGrid';

const FooterRowWrapper = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 'inherit',
  columnSpacing: 2,
  minHeight: 'var(--height)',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '0.1px solid var(--DataGrid-rowBorderColor)',
  borderTop: '0.1px solid transparent',
  backgroundColor: COLORS.subtotalTaxDiscountBg,
  color: COLORS.subtotalTaxDiscount,
  paddingRight: '10px',
  '&.py-hide-total-row, &.py-hide-total-row:hover': {
    backgroundColor: COLORS.greyedBackgroundColor,
  },
  '&:last-child': {
    backgroundColor: COLORS.footerHeader,
    borderBottom: '0',
    color: theme.palette.primary.contrastText,
  },
  '&:hover': {
    borderColor: COLORS.HoverNFocus,
  },
}));

const FooterRowItem = ({
  children,
  testId,
  onClick,
  className,
  ...props
}: {
  children?: ReactNode;
  testId?: string;
  props?: GridProps;
  className?: string;
  onClick?: MouseEventHandler;
}) => (
  <Grid item md={3} textAlign="right" paddingLeft="10px" {...props} data-testid={testId} onClick={onClick} className={className}>
    {children}
  </Grid>
);

const rowTitles = {
  Subtotal: 'Subtotal',
  Tax: 'Tax',
  Discount: 'Discount',
  Total: 'Totals',
};

const PricingTableTotalFooterRow = ({
  title,
  value,
  onClick,
  hidden,
  children,
}: {
  title: string;
  value: string;
  onClick?: MouseEventHandler;
  hidden?: boolean;
  isEditable?: boolean;
  children?: ReactNode;
}) => {
  const calculatedValue = (value: string | undefined) => {
    if (!value) return;
    return `$${value}`;
  };

  return (
    <FooterRowWrapper
      className={`py-total-row ${hidden ? 'py-hide-total-row' : ''}`}
      container
      data-testid={`${rowTitles[title].toLowerCase()}-row`}
      onClick={onClick}
    >
      <FooterRowItem />
      <FooterRowItem testId={`title-${rowTitles[title].toLowerCase()}`}>
        <span>{rowTitles[title]}</span>
      </FooterRowItem>
      <FooterRowItem testId={`input-${rowTitles[title].toLowerCase()}`} className="py-number-input-no-arrow">
        {children}
      </FooterRowItem>
      <FooterRowItem testId={`value-${rowTitles[title].toLowerCase()}`}>
        <span>{calculatedValue(value)}</span>
      </FooterRowItem>
    </FooterRowWrapper>
  );
};

export default PricingTableTotalFooterRow;
