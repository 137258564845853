import { useQuery } from 'react-query';
import { activitiesClient } from 'services/api';
import { useInsightsContext } from '../InsightsProvider';
import { ActivityDto } from '../types';

const REFRESH_INTERVAL_IN_MS = 60 * 1000;

type ActivitiesResponse = {
  activities: ActivityDto[];
};

export const fetchActivitiesForDocument = (documentId?: string) => async () => {
  if (!documentId) {
    return Promise.resolve([]);
  }

  const response = await activitiesClient.get<ActivitiesResponse>(`/document/${documentId}`);
  return response.activities;
};

export function useQueryActivities({ documentId }: { documentId?: string }) {
  const { setError } = useInsightsContext();
  const { data, isLoading } = useQuery(['activities', documentId], fetchActivitiesForDocument(documentId), {
    refetchInterval: REFRESH_INTERVAL_IN_MS,
    enabled: !!documentId,
    onError: () => setError('activitiesNotLoaded', true),
  });

  const result = !data ? [] : data;
  return {
    result,
    isLoading,
  };
}
