import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { useInsightsContext } from '../InsightsProvider';

export const formatAsPrice = (price: number, locale: string) =>
  price.toLocaleString(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: price % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  });

export function useGetDocumentPrice(documentId: string) {
  const { i18n } = useTranslation();
  const { setError } = useInsightsContext();

  const { data } = useQuery({
    queryKey: ['documents', documentId],
    queryFn: () => apiDocumentRepository.getDocumentById(documentId),
    enabled: !!documentId,
    onError: () => setError('documentPriceNotLoaded', true),
  });

  return {
    price: formatAsPrice(data?.price ?? 0, i18n.language || 'en-US'),
  };
}
