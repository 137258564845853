import { createContext, useContext, useEffect, useState } from 'react';
import { DocumentDto } from './types';
import { useQueryDocuments } from './useQueryDocuments';

type InsightsErrorTypes = 'documentListNotLoaded' | 'documentPriceNotLoaded' | 'activitiesNotLoaded' | 'documentInfoNotFound';

export type InsightsContext = {
  documentId: string;
  setCurrentDocumentId: (documentId: string) => void;
  documents: DocumentDto[];
  getCurrentDocument: () => DocumentDto | undefined;
  errors: Partial<Record<InsightsErrorTypes, boolean>>;
  setError: (key: InsightsErrorTypes, isError: boolean) => void;
  hasErrors: boolean;
};

export const InsightsContext = createContext<InsightsContext | null>(null);

export const useInsightsContext = () => {
  const context = useContext<InsightsContext | null>(InsightsContext);
  if (!context) throw new Error('useInsightsContext must be used within an InsightsProvider');
  return context;
};

export const InsightsProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentDocumentId, setCurrentDocumentId] = useState('');
  useEffect(() => {
    document.addEventListener('insights:opened', (e: InsightsOpenedEvent) => {
      setCurrentDocumentId(e.detail.documentId);
    });
    return () => document.removeEventListener('insights:opened', () => setCurrentDocumentId(currentDocumentId));
  }, [currentDocumentId]);

  const [errors, setErrors] = useState<Partial<Record<InsightsErrorTypes, boolean>>>({});
  const setError = (key: InsightsErrorTypes, isError: boolean) => {
    setErrors((prev) => ({ ...prev, [key]: isError }));
  };
  const hasErrors = Object.values(errors).some((error) => error);
  useEffect(() => {
    setErrors({ documentListNotLoaded: errors['documentListNotLoaded'] });
  }, [currentDocumentId]);

  const { documents, isLoading: isLoadingDocumentsList } = useQueryDocuments({
    onError: () => setError('documentListNotLoaded', true),
  });
  const getCurrentDocument = () => documents.find((document) => document.id === currentDocumentId);

  useEffect(() => {
    if (isLoadingDocumentsList) return;
    if (!errors['documentListNotLoaded'] && !getCurrentDocument()) {
      setError('documentInfoNotFound', true);
    }
  }, [currentDocumentId, isLoadingDocumentsList]);

  return (
    <InsightsContext.Provider
      value={{
        documentId: currentDocumentId,
        documents,
        setCurrentDocumentId,
        getCurrentDocument,
        setError,
        errors,
        hasErrors,
      }}
    >
      {children}
    </InsightsContext.Provider>
  );
};
