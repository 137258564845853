import { Typography } from '@mui/material';
import { useInsightsContext } from '../InsightsProvider';
import { useGetDocumentPrice } from './useGetDocumentPrice';

export function DocumentPrice() {
  const { documentId, hasErrors } = useInsightsContext();
  const { price } = useGetDocumentPrice(documentId);

  return hasErrors ? null : (
    <Typography variant="body2" data-testid="insights-header-price">
      {price}
    </Typography>
  );
}
