import { useQuery, UseQueryOptions } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

type UseQueryDocumentProps = {
  onError: UseQueryOptions['onError'];
};

export function useQueryDocuments({ onError }: UseQueryDocumentProps) {
  const { data, isLoading } = useQuery(['documents'], apiDocumentRepository.list, { onError });

  const documents = !data ? [] : data.documents.filter((doc) => doc.editorVersion === 3).filter((doc) => !doc.deleted_at);
  return {
    documents,
    isLoading,
  };
}
