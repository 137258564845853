import { ColumnTypeIdentifier, TableRowType, TotalRowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import { GridApiPro } from '@mui/x-data-grid-pro';
import PricingTableTotalFooterRow from './PricingTableTotalFooterRow';
import useTableRowSettingsPanel from '../../../SidePanel/content/TableSettings/RowSettings/useTableRowSettingsPanel';
import { selectContentTable } from 'components/editor/grid/reduxStore/editorSlice';
import { RootState } from 'components/editor/grid/reduxStore/Store';
import { useSelector } from 'react-redux';

type PricingTableTotalsFooterType = {
  tableApi: GridApiPro;
  isEditState: boolean;
  totalRows: TableRowType[];
  sumOfSubtotalColumn: number;
  sectionId: string;
  blockId: string;
};

export const PricingTableTotalsFooter = ({
  tableApi,
  isEditState,
  totalRows,
  sumOfSubtotalColumn,
  sectionId,
  blockId,
}: PricingTableTotalsFooterType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { columns } = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId));
  const { handleRowSettingsPanel } = useTableRowSettingsPanel();
  // const getParams = () => {
  //   const column = columns.find((column) => (column as PyColumnType)?.columnType === ColumnTypeIdentifier.PRICE);
  //   return {
  //     colDef: {
  //       ...column,
  //       type: 'number',
  //     },
  //     field: column?.field,
  //   };
  // };

  const handleRowClick = (row: TableRowType) => {
    if (!isEditState) return;
    // TODO: Input won't work unless we add StopPropagation.. However, stopPropagation will stop the row settings panel from toggling. I tried to run handleRowSettingsPanel in the callback but it didn't work. looks like it's setToggledTableSettingsPanel that's causing the issue.
    // event.stopPropagation();
    // event.preventDefault();

    const cellParams = tableApi.getCellParams(row.id, ColumnTypeIdentifier.PRICE);

    //TODO: Suggestion: We should be able to use the same function for both the footer and the rows instead of triggering cellClick to avoid side effects.
    // tableApi.publishEvent('cellClick', cellParams, event);
    handleRowSettingsPanel(cellParams, { api: tableApi });
  };

  const getRowValue = (row: TableRowType): string => {
    if (row.rowType === TotalRowTypesIdentifier.SUBTOTAL) {
      return sumOfSubtotalColumn.toString();
    }
    return '';
  };

  return (
    <>
      {totalRows.map((row) => {
        // const tableParams = { row, ...getParams(), id: row.id };
        return (
          <PricingTableTotalFooterRow
            key={row.id}
            onClick={() => handleRowClick(row)}
            title={row.rowType}
            hidden={row.isRowHidden}
            value={getRowValue(row)}
          >
            {/* //TODO: use the updated input from sp-1158 */}
            <input />
          </PricingTableTotalFooterRow>
        );
      })}
    </>
  );
};
